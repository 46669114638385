<template>
	<b-row>
		<b-col class="col-12">
			<b-tabs v-model="tabIndex">
				<b-tab
					v-for="(tabInfo, index) in tabs"
					:key="index">
					<template slot="title">
						<router-link
							:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
							:to="{ name: tabInfo.name }"
							class="list-group-item btn text-left">
							{{ translate(tabInfo.translate_key) }}
						</router-link>
					</template>
					<div
						v-if="tabIndex === index"
						class="p-3">
						<router-view />
					</div>
				</b-tab>
			</b-tabs>
		</b-col>
	</b-row>
</template>

<script>
import { Inventory } from '@/translations';

export default {
	name: 'InventoryLogs',
	messages: [Inventory],
	data() {
		return {
			tabIndex: 0,
			tabs: [
				{ name: 'InventoryChanges', translate_key: 'InventoryChanges_HeaderTitle' },
				{ name: 'ManualOrdersLogs', translate_key: 'ManualOrdersLogs_HeaderTitle' },
				{ name: 'ManualOrderTypes', translate_key: 'ManualOrderTypes_HeaderTitle' },
			],
		};
	},
};
</script>

<style scoped>

</style>
